<template>
  <div>
    <div v-if="meeting" class="card card-custom p-5 gutter-b">
      <div class="card-body p-0">
        <div class="row">
          <div class="col-md-12 py-2 col-sm-12">
            <label for="input-large">Project Code</label>

            <b-form-input
              :value="meeting.meeting.project.code"
              disabled
              size="lg"
            ></b-form-input>
          </div>

          <div class="col-md-12 py-2 col-sm-12">
            <label for="input-large">Project Name</label>

            <b-form-textarea
              :value="meeting.meeting.project.name"
              disabled
              size="lg"
            ></b-form-textarea>
          </div>

          <div class="col-md-12 py-2 col-sm-12">
            <label for="input-large">Meeting Code</label>

            <b-form-input
              :value="meeting.meeting.code"
              disabled
              size="lg"
            ></b-form-input>
          </div>

          <div class="col-md-12 py-2 col-sm-12">
            <label for="input-large">Agenda</label>

            <b-form-textarea
              :value="meeting.meeting.subject"
              disabled
              size="lg"
            ></b-form-textarea>
          </div>

          <div class="col-md-12 py-2 col-sm-12 d-flex justify-content-end">
            <a
              @click="download"
              :disabled="loading"
              class="btn btn-light-success font-size-sm"
            >
              Download <i class="flaticon-download ml-2"></i>
            </a>
          </div>
        </div>
      </div>
    </div>

    <div v-if="meeting" class="card card-custom p-5 gutter-b">
      <div class="card-header p-0">
        <div class="card-title">
          <h3 class="card-label">Meeting Info</h3>
        </div>
      </div>

      <div class="card-body p-0">
        <div class="row">
          <div class="col-md-2 col-sm-6 col-6 py-3">
            <b>Project</b>
          </div>

          <div class="col-md-2 col-sm-6 col-6 py-3">
            :
            {{ meeting.meeting.project ? meeting.meeting.project.code : null }}
          </div>

          <div class="col-md-2 col-sm-6 col-6 py-3">
            <b>Place</b>
          </div>

          <div class="col-md-2 col-sm-6 col-6 py-3">
            : {{ meeting.meeting ? meeting.meeting.place : null }}
          </div>

          <div class="col-md-2 col-sm-6 col-6 py-3">
            <b>Meeting Number</b>
          </div>

          <div class="col-md-2 col-sm-6 col-6 py-3">
            : {{ meeting.meeting ? meeting.meeting.code : null }}
          </div>

          <div class="col-md-2 col-sm-6 col-6 py-3">
            <b>Agenda</b>
          </div>

          <div class="col-md-2 col-sm-6 col-6 py-3">
            : {{ meeting.meeting ? meeting.meeting.subject : null }}
          </div>

          <div class="col-md-2 col-sm-6 col-6 py-3">
            <b>Date</b>
          </div>

          <div class="col-md-2 col-sm-6 col-6 py-3">
            :
            {{
              meeting.meeting
                ? moment(meeting.meeting.date).format("DD-MM-YYYY hh:mm:ss")
                : null
            }}
          </div>

          <div class="col-md-2 col-sm-6 col-6 py-3">
            <b>Leader</b>
          </div>

          <div class="col-md-2 col-sm-6 col-6 py-3">
            : {{ meeting.meeting ? meeting.meeting.leader : null }}
          </div>
        </div>
      </div>
    </div>

    <div v-if="meeting" class="card card-custom p-5 gutter-b">
      <div class="card-header p-0">
        <div class="card-title">
          <h3 class="card-label">Attendants</h3>
        </div>
      </div>

      <div class="card-body p-0">
        <complete-table :data="meeting.attendant" :loading="false">
          <template #header>
            <tr class="text-left">
              <th class="pl-7">Name</th>
              <th class="pl-7">Agency</th>
              <th class="pl-7">Email</th>
              <th class="pl-7">Signature</th>
            </tr>
          </template>

          <template #defaultBody>
            <template v-for="(item, i) in meeting.attendant">
              <tr v-bind:key="i" class="border-0">
                <td class="pl-0 pt-8">
                  <span
                    class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.name }}
                  </span>
                </td>

                <td class="pl-0 pt-8">
                  <span
                    class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.department ? item.department.name : null }}
                  </span>
                </td>

                <td class="pl-0 pt-8">
                  <span
                    class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.email }}
                  </span>
                </td>

                <td class="pl-0 pt-8">
                  <span
                    class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.signature }}
                  </span>
                </td>
              </tr>
            </template>
          </template>
        </complete-table>
      </div>
    </div>

    <div v-if="meeting" class="card card-custom p-5 gutter-b">
      <div class="card-header p-0">
        <div class="card-title">
          <h3 class="card-label">Point MOM</h3>
        </div>
      </div>

      <div class="card-body p-0">
        <complete-table :data="meeting.mom_point" :loading="false">
          <template #header>
            <tr class="text-left">
              <th class="pl-7">Point</th>
              <th class="pl-7">Description</th>
              <th class="pl-7">Cause Analysis</th>
              <th class="pl-7">Deadline</th>
              <th class="pl-7">Status</th>
              <th class="pl-7">PIC</th>
              <th class="pl-7">Response</th>
            </tr>
          </template>

          <template #defaultBody>
            <template v-for="(item, i) in meeting.mom_point">
              <tr v-bind:key="i" class="border-0">
                <td class="pl-0 pt-8">
                  <span
                    class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.point }}
                  </span>
                </td>

                <td class="pl-0 pt-8">
                  <span
                    class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.description }}
                  </span>
                </td>

                <td class="pl-0 pt-8">
                  <span
                    class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.cause }}
                  </span>
                </td>

                <td class="pl-0 pt-8">
                  <span
                    class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ moment(item.deadline).format("DD-MM-YYYY hh:mm:ss") }}
                  </span>
                </td>

                <td class="pl-0 pt-8">
                  <span
                    class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.status }}
                  </span>
                </td>

                <td class="pl-0 pt-8">
                  <span
                    class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.pic ? item.pic.name : null }}
                  </span>
                </td>

                <td class="pl-0 pt-8">
                  <span
                    class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.response }}
                  </span>
                </td>
              </tr>
            </template>
          </template>
        </complete-table>
      </div>
    </div>

    <div v-if="meeting" class="card card-custom p-5 gutter-b">
      <div class="card-header p-0">
        <div class="card-title">
          <h3 class="card-label">Attachment</h3>
        </div>
      </div>

      <div class="card-body p-0">
        <complete-table :data="meeting.file" :loading="false">
          <template #header>
            <tr class="text-left">
              <th class="pl-7">File</th>
            </tr>
          </template>

          <template #defaultBody>
            <template v-for="(item, i) in meeting.file">
              <tr v-bind:key="i" class="border-0">
                <td class="pl-0 pt-8">
                  <span
                    class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.filename }}
                  </span>
                </td>
              </tr>
            </template>
          </template>
        </complete-table>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapState } from "vuex";
import { GET_MEETING_BY_ID } from "@/core/services/store/meeting.module";
import CompleteTable from "../../../content/widgets/advance-table/CompleteTable";
import ApiService from "@/core/services/api.service";
import { showToast } from "@/core/helper/toast.helper";

export default {
  components: { CompleteTable },
  data() {
    return {
      filter: {
        code: null,
        nickname: null,
        meeting_code: null,
        subject: null,
      },
      loading: false,
    };
  },
  computed: {
    ...mapState({
      meeting: (state) => state.meeting.meeting?.data,
    }),
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Detail Report Meeting" }]);

    this.$store.dispatch(GET_MEETING_BY_ID, {
      id: this.$route.params.id,
    });
  },
  methods: {
    download() {
      var fileUrl = "";
      ApiService.setHeader();

      this.loading = true;

      ApiService.post("meeting/pdf", {
        id: this.$route.params.id,
        code: this.meeting.meeting?.code,
      }).then((response) => {
        fileUrl = response.data.data.meeting?.mom_file;
        ApiService.postDownload("ftp/download", {
          file_url: fileUrl,
        })
          .then((responseDownload) => {
            const fileURL = window.URL.createObjectURL(
              new Blob([responseDownload?.data])
            );

            const fileLink = document.createElement("a");

            fileLink.href = fileURL;

            const pathArray = fileUrl.split("/");
            var fileName = pathArray.at(-1);

            fileLink.setAttribute("download", fileName);

            document.body.appendChild(fileLink);

            fileLink.click();

            this.loading = false;
          })
          .catch(() => {
            showToast("Failed", "Download failed, try again", "danger");
          });
      });
    },
  },
};
</script>
